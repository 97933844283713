export const initialTasks = {
  task_1: {
    description: "Greet the doctor",
    completed: false
  },
  task_2: {
    description: "Describe your symptoms",
    completed: false
  },
  task_3: {
    description: "Ask about medication",
    completed: false
  },
  task_4: {
    description: "Thank the doctor and say goodbye",
    completed: false
  }
};